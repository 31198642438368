<template>
  <div id="app">
    <div class="loading" v-if="!openid">
      <van-loading type="spinner" size="40px" vertical>
        获取中请等待
      </van-loading>
    </div>
    <template v-else>
      <div class="openid">
        {{ openid }}
      </div>
      <div class="btn" :data-clipboard-text="openid">
        <van-button type="default" class="aaa" @click="copy"
          >点击复制</van-button
        >
      </div>
    </template>
  </div>
</template>

<script>
import axios from "axios";
import ClipboardJS from "clipboard";
//https://open.weixin.qq.com/connect/oauth2/authorize?appid=APPID&redirect_uri=REDIRECT_URI&response_type=code&scope=SCOPE&state=STATE#wechat_redirect
export default {
  name: "App",
  data() {
    return {
      url: "http://localhost:8081",
      openid: "",
    };
  },
  mounted() {
    console.log(this.$route);
    this.getCode();
    // window.location.href=`https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx49bc781f385abe13&redirect_uri=${this.url}&response_type=code&scope=SCOPE&state=STATE#wechat_redirect`
  },
  methods: {
    copy() {
      const clipboard = new ClipboardJS(".btn");
      const self = this;
      clipboard.on("success", function (e) {
        self.$toast("复制成功");
      });
    },
    getCode() {
      const { code } = this.$route.query;
      if (!code) {
        this.$toast("参数非法");
        return;
      }
      axios.get("/api/zj/getOpenId?code=" + code).then((res) => {
        if (res.data.code == 200) {
          this.openid = res.data.data.openid;
          return;
        }
        this.$toast(res.data.msg);
        console.log(res, "系统返回值");
      });
    },
  },
  components: {},
};
</script>

<style scoped>
* {
  padding: 0;
  margin: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
.loading {
  text-align: center;
}
.openid {
  padding: 40px 0;
  text-align: center;
  font-size: 16px;
}
</style>
